<template>
	<div class="main-contents">
		<div class="tit">관리총괄</div>
		<div class="search-box interval">
			<SelectComp list="01:승인,02:미승인" :isAll="true" title="관리총괄 승인여부" v-model="srchFilter.apprDiv"/>
			<nbsp/>
			<SelectComp list="corp:수요기업명,mber:관리총괄명" v-model="srchFilter.srchTxtDiv" @change="srchTxtPlaceholder = $event.target.selectedOptions[0].label"/>
			<InputComp v-model="srchFilter.srchTxt" :placeholder="srchTxtPlaceholder" @keyup.enter="getDcoGmgInfoList('1')"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getDcoGmgInfoList('1')"/>
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="5%">
					<col width="10%">
					<col width="10%">
					<col width="26%">
					<col width="26%">
					<col width="13%">
					<col width="10%">
				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>관리총괄명</th>
						<th>회원번호</th>
						<th>아이디</th>
						<th>수요기업명</th>
						<th>회원가입일</th>
						<th>관리총괄 승인</th>
					</tr>
				</thead>
				<tbody v-if="dcoGmgList.length > 0">
					<!-- 최대 25건 조회 -->
					<tr v-for="(row, rowIdx) in dcoGmgList" :key="rowIdx">
						<!-- <td class="score">{{rowIdx + 1}}</td> -->
						<td class="score">{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (rowIdx + 1)}}</td>
						<td class="score name" @click="openPopup(row.mberSeq)">{{row.mberNm}} &#9654;</td>
						<td class="score">{{row.mberSeq}}</td>
						<td class="score">{{row.loginId}}</td>
						<td class="score">{{row.corpNm}}</td>
						<td class="score">{{row.regYmd | date('yyyy.MM.dd')}}</td>
						<td class="score resume" :class="row.mberStatusCd == '01' ? 'apvl' : 'resume'">
							<div v-if="row.mberStatusCd == '01'">승인</div>
							<div class="btn rq" v-else @click="gmgAppr(rowIdx)">승인하기 &#9654;</div>
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<!-- 관련내용 없을 경우 -->
					<tr>
						<td colspan="7" class="none">등록된 관리총괄 회원이 없습니다!</td>
					</tr>
				</tbody>
			</table>
		</div>
		
		<!-- 페이징부분 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	data() {
		return {
			srchFilter : {
				apprDiv : '',
				srchTxtDiv : 'corp',
				pageIndex : 1,
			},

			dcoGmgList : [],
			// 페이징
			pageInfo : {},

			srchTxtPlaceholder : '수요기업명',
		}
	},

	components : {
		pagingComp
	},

	beforeMount() {
		var keys = Object.keys(this.srchFilter);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.srchFilter[key] = this.$route.params[key];
			}
		}
		this.getDcoGmgInfoList();
	},

	methods : {
		getDcoGmgInfoList(div) {
			var param = this.srchFilter;
			param.pageUnit = '25';
			param.pageSize = '10';
			if(div) param.pageIndex = 1;

			this.$.httpPost('/api/mem/adm/dco/getDcoGmgInfoList', param	)
				.then(res => {
					//console.log('getDcoGmgInfoList RESULT', res);

					this.dcoGmgList = res.data.dcoGmgList;
					this.pageInfo = res.data.pageInfo;
				}).catch(this.$.httpErrorCommon);
		},

		// 관리총괄 승인하기
		gmgAppr(idx) {
			var obj =  this.dcoGmgList[idx];

			this.$.popup('/adm/mem/MEM923P01', {mberNm:obj.mberNm, loginId:obj.loginId, corpNm:obj.corpNm})
				.then(res => {
					if(res) {
						var param = {};
						param.dcoMberSeq = obj.mberSeq;
						param.recvCorpNm = obj.corpNm;

						//console.log('admGmgApproval PARAM', param);

						this.$.httpPost('/api/mem/adm/dco/admGmgApproval', {dcoMberSeq : obj.mberSeq})
							.then(() => {
								this.getDcoGmgInfoList();
							}).catch(this.$.httpErrorCommon);
					}
				})
		},

		openPopup(seq) {
			var param = {};
			param.reqMberSeq = seq;
			param.div = 'gmg';

			this.$.popup('/adm/mem/MEM923P02', param);
		},

		// 페이징 이동
		goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getDcoGmgInfoList();
		},
	}
}
</script>